<template>
    <div class="form_box">
        <div class="logo">
            <img
                src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/logo1.png"
                width="90px"
                alt=""
            />
        </div>
        <div class="title">
            <div class="h1">FIREKB智能创意平台</div>
            <div class="p">全域内容分发·赋能增长获客·全链路赋能</div>
        </div>
        <div class="form">
            <el-form
                label-position="top"
                ref="form"
                :model="form"
                :rules="rules"
                :hide-required-asterisk="true"
                label-width="80px"
            >
                <el-form-item label="账号" prop="username">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        type="password"
                        v-model="form.password"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="savePass">
                        <el-checkbox v-model="savePass">记住密码</el-checkbox>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        :loading="isLogin"
                        @click="onSubmit"
                        >登录</el-button
                    >
                </el-form-item>

                <el-form-item prop="xieyi">
                    <el-checkbox v-model="form.xieyi" name="xieyi"
                        >登录即代表同意用户协议和隐私条款</el-checkbox
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { encryptionPassWord } from "../../utils/encryptionpassword";
import { login } from "@/api/login";
// import { get_role } from "@/api/role";
export default {
    name: "LoginForm",

    data() {
        return {
            savePass: false,
            isLogin: false,
            form: {
                username: "",
                password: "",
                xieyi: [],
            },
            rules: {
                username: [
                    { required: true, message: "请填写账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请填写密码", trigger: "blur" },
                ],
                xieyi: [
                    {
                        required: true,
                        message: "请阅读并且同意协议",
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    mounted() {},
    created() {
        let localUser = localStorage.getItem("user");
        if (localUser) {
            localUser = JSON.parse(localUser);
            this.form.username = localUser.username;
            this.form.password = localUser.password;
            this.savePass = true;
        } else {
            this.savePass = false;
        }
    },
    methods: {
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.isLogin = true;
                    let data = {
                        account: this.form.username,
                        password: encryptionPassWord(this.form.password),
                        client_id: "admin",
                        client_secret: "123456",
                        grant_type: "password",
                        origin: "password",
                    };
                    login(data).then((res) => {
                        // console.log(data);
                        this.isLogin = false;
                        if (res.code == 200) {
                            // get_role().then((res) => {
                            //     console.log(res);
                            // });
                            this.$store.commit("SEt_USER_INFO", res.data);
                            this.$store.commit("SET_TOKEN", res.data.token);
                            localStorage.setItem("auto_token", res.data.token);
                            if (this.savePass) {
                                let user = {
                                    username: this.form.username,
                                    password: this.form.password,
                                    savePass: this.savePass,
                                };
                                user = JSON.stringify(user);
                                localStorage.setItem("user", user);
                                // console.log(localStorage.getItem("user"));
                            } else {
                                localStorage.setItem("user", null);
                            }
                            this.$router.push({ path: "/home/index" });
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.form_box {
    padding-top: 80px;
    max-width: 350px;
    margin: 0 auto;
}
.title {
    line-height: 32px;
    margin-top: 35px;
    .h1 {
        font-size: 20px;
        font-weight: bold;
    }
}
.form {
    margin-top: 48px;
    /deep/ .el-form-item__label {
        line-height: 1;
    }
    /deep/ .el-button {
        width: 100%;
        margin-top: 40px;
        height: 48px;
        background: linear-gradient(270deg, #4d56ff 0%, #d000ff 100%);
        border-radius: 8px 8px 8px 8px;
        border: none;
    }
}
.savePass {
    display: flex;
    flex-direction: row-reverse;
}
</style>