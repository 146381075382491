<template>
    <div class="login_conten">
        <div class="login_card">
            <LOGIN_CARD />
        </div>
        <div class="form_box">
            <LOGIN_FORM />
        </div>
    </div>
</template>

<script>
import LOGIN_CARD from "@/components/login/card";
import LOGIN_FORM from "@/components/login/login_form";
export default {
    name: "login1",
    components: {
        LOGIN_CARD,
        LOGIN_FORM,
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.login_conten {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    .form_box {
        flex: 0 0 32%;
        // background-color: aqua;
    }
    .login_card {
        flex: 1;
        background: linear-gradient(to top left, #0053ff, #d000ff);
        border-radius: 20px;
    }
}
</style>