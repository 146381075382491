<template>
    <div class="card_content">
        <div class="card_hd">
            <img src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/login_card_logo.png" alt="" />
        </div>
        <div class="card_body">
            <div class="card_box">
                <div class="page_card">
                    <img src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/page_card.png" width="100%"
                        alt="" />
                    <div class="quan"></div>
                    <div class="user_img"></div>
                    <div class="gn_img"></div>
                    <div class="preview_img"></div>
                    <div class="cover_img"></div>
                    <div class="feiji_img"></div>
                    <div class="xing_img"></div>
                    <div class="maos_img maos1"></div>
                    <div class="maos_img maos2"></div>
                </div>
            </div>
        </div>
        <div class="card_foo">
            <div>智能创意技术·全域内容分发·赋能增长获客</div>
            <div>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备2022154992号-2</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loginCard",

    data() {
        return {};
    },

    mounted() { },

    methods: {},
};
</script>

<style lang="less" scoped>
.card_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .card_hd {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }

    .card_body {
        flex: 1;
        position: relative;
        padding: 0 200px 0 140px;
        box-sizing: border-box;
        min-height: 600px;
        display: flex;
        align-items: center;
    }

    .card_foo {
        padding: 44px 50px;
        display: flex;
        justify-content: space-between;
        color: #fff;

        a {
            color: #fff;
        }
    }
}

.card_box {
    width: 730px;
    height: 550px;
    margin: 0 auto;
    position: relative;
    padding: 0 80px 110px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_card {
    width: 640px;
    height: 440px;
    position: relative;
    z-index: 99;

    // padding: 0 80px 0 0;
    img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 99;
    }
}

.quan {
    width: 235px;
    height: 235px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/quan.png);
    position: absolute;
    bottom: -112px;
    left: -15px;
    // transform: translate(-660px, 360px) scale(0);
    animation: quan cubic-bezier(0, 0, 0.2, 1) 1s;
}

@keyframes quan {
    0% {
        transform: translate(-660px, 360px) scale(0) rotate(0deg);
    }

    100% {
        transform: translate(0, 0) scale(1) rotate(3600deg);
    }
}

.user_img {
    width: 240px;
    height: 120px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/user_img.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: -64px;
    left: 70px;
    z-index: 999;
    animation: user 1s linear;
}

@keyframes user {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
        transform: rotateX(-90deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0);
    }
}

.gn_img {
    width: 330px;
    height: 220px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/gn_img.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: -122px;
    right: -70px;
    z-index: 999;
    transform-origin: center left;
    animation: gn 2s linear;
}

@keyframes gn {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.preview_img {
    width: 165px;
    height: 122px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/preview.png);
    background-size: 100% 100%;
    position: absolute;
    top: 94px;
    right: -80px;
    z-index: 9999;
    animation: preview 1s linear 2;
}

@keyframes preview {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cover_img {
    width: 115px;
    height: 138px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/cover_img.png);
    background-size: 100% 100%;
    position: absolute;
    top: 120px;
    left: 273px;
    z-index: 999;
    animation: cover 1s linear;
}

@keyframes cover {
    0% {
        transform: translate(-63px, -58px);
    }

    60% {
        transform: translate(-63px, -58px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.feiji_img {
    width: 73px;
    height: 45px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/feiji.png);
    background-size: 100% 100%;
    position: absolute;
    top: 260px;
    right: -34px;
    z-index: 999;
    animation: feiji linear 0.5s;
    transform-origin: left;
}

@keyframes feiji {
    0% {
        transform: translate(0, 0) scale(1);
    }

    100% {
        transform: translate(-83px, -40px) scale(0);
    }

    // 100% {
    //     transform: translate(0, 0) scale(1);
    // }
}

.maos_img {
    width: 13px;
    height: 13px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/maos.png);
    background-size: 100% 100%;
    position: absolute;
    z-index: 999;

    &.maos1 {
        top: 120px;
        left: 260px;
        animation: mao2 0.5s linear;
    }

    &.maos2 {
        top: 370px;
        left: 57px;
        animation: mao2 0.5s linear;
    }
}

@keyframes mao2 {
    0% {
        opacity: 0;
        transform: translate(15px, 12px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.xing_img {
    width: 16px;
    height: 16px;
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/v3/login/xing.png);
    background-size: 100% 100%;
    position: absolute;
    z-index: 999;
    top: 226px;
    left: 500px;
    animation: xing 1s linear 0.5s;
}

@keyframes xing {
    0% {
        transform: translate(0, 0) rotate(0);
    }

    100% {
        transform: translate(68px, -46px) rotate(-3600deg);
    }
}
</style>