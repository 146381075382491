import request from '@/utils/request'
import qs from "qs";
export function login(data) {
    const login_data = qs.stringify(data)
    // console.log(login_data);
    return request({
        url: '/api/oauth/Login',
        method: 'post',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        // data: qs.stringify(data)
        data
    })
}